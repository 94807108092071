import React from "react";
import { Link } from "gatsby";

function Pagination({ totalPages, currentPage, baseUrl }) {
  const blueColor = "#3A7AFF";

  const paginatedValues = () => {
    if (currentPage < totalPages - 4) {
      return [
        {
          copy: currentPage,
          selected: true,
        },
        {
          link: baseUrl + "/p/" + (currentPage + 1),
          copy: currentPage + 1,
          selected: false,
        },
        {
          link: baseUrl + "/p/" + (currentPage + 2),
          copy: currentPage + 2,
          selected: false,
        },
        { copy: "..", selected: false },
        {
          link: baseUrl + "/p/" + totalPages,
          copy: totalPages,
          selected: false,
        },
      ];
    } else {
      return [
        {
          link: baseUrl + "/p/" + (totalPages - 4),
          copy: totalPages - 4,
          selected: currentPage === totalPages - 4,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 3),
          copy: totalPages - 3,
          selected: currentPage === totalPages - 3,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 2),
          copy: totalPages - 2,
          selected: currentPage === totalPages - 2,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 1),
          copy: totalPages - 1,
          selected: currentPage === totalPages - 1,
        },
        {
          link: baseUrl + "/p/" + totalPages,
          copy: totalPages,
          selected: currentPage === totalPages,
        },
      ];
    }
  };

  return (
    <>
      <div className="text-2xl sm:w-10/12 w-full my-10 xs:font-primary text-newGray mx-auto flex flex-row justify-center">
        {currentPage > 2 ? (
          <span className="hover:text-lightBlue cursor-pointer mr-5">
            <Link to={baseUrl + "/p/1"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg>
            </Link>
          </span>
        ) : (
          <></>
        )}
        {currentPage > 1 ? (
          <span className="hover:text-lightBlue cursor-pointer">
            <Link to={baseUrl + "/p/" + (currentPage - 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
              </svg>
            </Link>
          </span>
        ) : (
          <></>
        )}
        <div className="mx-4 md:mr-7 inline-block">
          {paginatedValues()?.map((p, i) => {
            if (p.copy > 0 || p.copy === "..") {
              return (
                <Link className="mx-2" to={p?.link} key={i} style={p.selected ? { color: blueColor } : { color: "" }}>
                  {p?.copy}
                </Link>
              );
            }
          })}
        </div>
        {currentPage < totalPages ? (
          <span className="hover:text-lightBlue cursor-pointer mr-5">
            <Link to={baseUrl + "/p/" + (currentPage + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Link>
          </span>
        ) : (
          <></>
        )}
        {currentPage < totalPages - 1 ? (
          <span className="hover:text-lightBlue cursor-pointer">
            <Link to={baseUrl + "/p/" + totalPages}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Link>
          </span>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
export default Pagination;
