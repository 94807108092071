import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/layout/footer";
import SEO from "components/seo";
import Pagination from "components/TranslatorHub/pagination";
import Social from "components/TranslatorHub/Social";
import { graphql, Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import moment from "moment";
import React, { useEffect, useState } from "react";

import TranslatorHubHeader from "./TranslatorHubHeader/TranslatorHubHeader";
import Form from "./TranslatorHubDetails/getForm";
export const translationCategoryQuery = graphql`
  query translationCategoryQuery(
    $limit: Int!
    $skip: Int!
    $category: String!
  ) {
    allTranslateHub(
      sort: { fields: [data___seo_project_create_date], order: DESC }
      filter: { data: { category_name: { eq: $category } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            cover_image
            language
            slug
            seo_h1
            category
            category_name
            alt_tag
            seo_project_create_date
            seo_project_small_desc
            seo_project_id
            read_time
          }
        }
      }
    }
  }
`;

const CategoryPage = (props) => {
  const title = "Translation Blog - Tips for Translators | Tomedes";
  const description =
    "Tomedes special translators' zone - We share tips for translators, freelancers, languages lovers, interpreters, editors, proofreaders, content writers and localization experts.";

  const TranslationHubData = props.data.allTranslateHub.edges;

  var canonical =
    "/translator-hub.php/category/" + props.pageContext.category + "/";
  if (props.pageContext.currentPage !== 1) {
    canonical =
      "/translator-hub.php/category/" +
      props.pageContext.category +
      "/p/" +
      props.pageContext.currentPage;
  }

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: "optimize.activate" });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };

  const variant = 1;

  const [paddingTop, setPaddingTop] = useState(false);

  if (variant) {
    setPaddingTop(true);
  }

  return (
    <React.Fragment>
      <TranslatorHubHeader styleOne={variant} title={"Translator's Blog"} />
      <SEO title={title} description={description} slug={canonical} />
      <div className="flex items-center justify-center w-full">
        <div className="relative flex justify-center md:flex-row flex-col  w-full max-w-screen-2xl mt-2 md:mt-32 pb-12">
          <div className="px-4 xl:px-0 lg:mr-20 xl:w-5/12  ">
            {TranslationHubData?.map(({ node }) => {
              const { data } = node;
              const {
                cover_image,
                alt_tag,
                category_name,
                seo_project_id,
                seo_project_create_date,
                seo_project_small_desc,
                slug,
                read_time,
                seo_h1,
              } = data;
              return (
                <div
                  className="w-full border-newBorder border-b-3 mb-7"
                  key={seo_project_id}
                >
                  <Link to={slug}>
                    <span className="text-2xl py-4 text-newGray hover:text-newLightBlue leading-none break-words">
                      {seo_h1}
                    </span>
                  </Link>
                  <Link to={slug}>
                    <OptimizedImage
                      src={cover_image}
                      alt={alt_tag}
                      lazyload={false}
                      className="w-[343px] sm:w-[590px] md:w-full h-[216px] sm:h-[370px] md:h-auto lg:h-[370px] object-cover mt-5"
                    />
                  </Link>
                  <p className="pb-2 pt-3 text-17 font-normal font-serif">
                    CATEGORY /{" "}
                    <span className="text-lightBlue mt-1 mb-2">
                      {category_name}
                    </span>
                  </p>
                  <i className="pb-5 inline-block font-light font-serif">
                    {moment(seo_project_create_date).format("DD/MM/YYYY")}
                  </i>
                  <p className="pb-5 font-opensans font-normal italic">
                    {seo_project_small_desc}
                  </p>
                  <Link to={slug}>
                    <i className="inline-flex mt-4 items-center pb-5 font-light font-serif text-newGray">
                      Read More{" "}
                      <FontAwesomeIcon icon={faAngleRight} className="ml-6" />
                    </i>
                  </Link>
                  <span className="block pb-5 font-light font-serif text-sm">
                    {read_time}
                  </span>

                  {/* <Link to={slug}>
                    <h1 className="text-2xl py-4 text-newGray hover:text-newLightBlue leading-none">
                      {seo_h1}
                    </h1>
                  </Link>
                  <Link to={slug}>
                    <OptimizedImage
                      src={cover_image}
                      alt={alt_tag}
                      className="w-full object-cover h-auto lg:h-[370px] "
                    />
                  </Link>
                  <p className="pb-2 pt-3 text-17  font-normal font-serif">
                    CATEGORY /{" "}
                    <span className="text-lightBlue mt-1 mb-2 ">
                      {category_name}
                    </span>
                  </p>
                  <i className="pb-5 inline-block font-light font-serif">
                    {moment(seo_project_create_date).format("DD/MM/YYYY")}
                  </i>
                  <p className="pb-5 font-opensans font-normal italic ">
                    {seo_project_small_desc}
                  </p>
                  <Link to={slug}>
                    <i className="inline-flex mt-4 items-center pb-5 font-light font-serif text-newGray">
                      Read More{" "}
                      <FontAwesomeIcon icon={faAngleRight} className="ml-6" />
                    </i>
                  </Link>
                  <span className="block pb-5 font-light font-serif text-sm">
                    {read_time}
                  </span>
                  <span className=""></span> */}
                </div>
              );
            })}
            <Pagination
              totalPages={props.pageContext.totalPages}
              currentPage={props.pageContext.currentPage}
              baseUrl={`/translator-hub.php/category/${props.pageContext.category}`}
            />
          </div>
          <div className=" sticky h-max   top-24">
            <Form />
            <div className="line border-b border-gray mt-12"></div>
            <div>
              <h1 className=" font-bold text-newGray text-xl mt-4">
                CATEGORIES
              </h1>
              <ul className="text-newGray">
                {props.pageContext.uniqueCategories.map((link, index) => (
                  <li
                    className="text-new py-2  hover:text-newLightBlue cursor-pointer"
                    key={index}
                  >
                    <Link to={`/translator-hub.php/category/${link}`}>
                      {link}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="line border-b border-gray mt-12"></div>
            <Social />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
export default CategoryPage;
