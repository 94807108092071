import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSubscribe } from "hooks/Forms/useSubscribe";
import { useLocation } from "@reach/router";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessageEmail,
  patternEmail,
  errorMessageName,
} from "utils/form-validation";
import Hero from "assets/frombgimg.svg";
import RedExclamation from "assets/redexclon.svg";

const Form = () => {
  const [formStatus, setForStatus] = useState(false);
  const { mutateAsync } = useSubscribe();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setForStatus(true);
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${Hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "12px",
        }}
        className="w-full lg:w-72 border border-newBorder py-10  mt-10 px-6 text-center "
      >
        <h2 className="text-2xl font-bold text-center text-white">
          <span className="text-orange"> </span>GET IN TOUCH
        </h2>
        <p className="text-base font-primary leading-6 font-normal text-center py-6 text-white">
          Need expert language assistance? Inquire now
        </p>{" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative">
            {errors.name && (
              <span className="text-red text-sm">{errorMessageName}</span>
            )}
            <input
              type="name"
              name="name"
              {...register("name", {
                required: true,
              })}
              placeholder="Name "
              className={`bg-white m-auto block text-sm mb-6 pl-2 w-11/12 lg:w-9/12 sm:w-10/12 border border-newBorder py-2 font-opensans ${
                errors.name
                  ? "border-red outline-none focus:border-[#ff0000]"
                  : ""
              }`}
            />
            {errors.name && (
              <img
                src={RedExclamation}
                alt="Error"
                className="absolute right-6 lg:right-[40px] top-[68%] transform -translate-y-1/2 w-4 h-4"
              />
            )}
          </div>
          <div className="relative">
            {errors.email && (
              <span className="text-red text-sm">{errorMessageEmail}</span>
            )}
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern: patternEmail,
              })}
              placeholder="Email "
              className={`bg-white m-auto block text-sm mb-6 pl-2 w-11/12 lg:w-9/12 sm:w-10/12 border border-newBorder py-2 font-opensans ${
                errors.email
                  ? "border-red outline-none focus:border-[#ff0000]"
                  : ""
              }`}
            />
            {errors.email && (
              <img
                src={RedExclamation}
                alt="Error"
                className="absolute right-6 lg:right-[40px] top-[78%] transform -translate-y-1/2 w-4 h-4"
              />
            )}
          </div>
          {formStatus ? (
            <span className="text-[#96D149] text-sm px-4">
              Email sent successfully, we'll get back to you shortly
            </span>
          ) : (
            <></>
          )}
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button
            type="submit"
            className="bg-[#FF7B16] w-11/12 lg:w-9/12 sm:w-10/12 py-3 text-borderColor font-bold px-4 text-sm block m-auto uppercase"
          >
            Submit
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default Form;
